import React from "react"
import * as styles from "./postsGroup.module.scss"

const LineSeparator = () => {
  return (
    <>
      <div className={styles.lineSeparator}></div>
      <div className={styles.lineSeparatorLabel}>Alle Module</div>
    </>
  )
}

export default LineSeparator
