import React from "react"
import * as styles from "./postsGroup.module.scss"
import LineSeparator from "./lineSeparator"

const PostsGroup = ({ title, active, children }) => {
  return (
    <>
    
      {title && <h2 className={styles.title}>{title}</h2> }
      <div className={styles.postsGroup}>{children}</div>
      {active && <LineSeparator />}
    </>
  )
}

export default PostsGroup
