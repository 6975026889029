import React, { useEffect, useState } from "react"
import { Link } from "gatsby"
import { navigate } from "@reach/router";
import { FaArrowRight, FaPlayCircle, GiConsoleController } from "react-icons/all"
import IconArrow from "../../images/Icons/icon_arrow.svg"
import IconVideo from "../../images/Icons/icon_video.svg"
import IconPdf from "../../images/Icons/icon_pdf.svg"
import * as styles from "./postCard.module.scss"

const Post = ({ type, sourcePosition, active, item, loggedIn, showPoint }) => {

    const [watched, setWatched] = useState('')
    const [fileUrl, setFileUrl] = useState(null)

    const getWatched = (id) => {

        let videoProgressJson = localStorage.getItem('videoProgress');
        console.log("videoProgressJson", videoProgressJson);

        if (videoProgressJson == null) return null;
        let progressArray = JSON.parse(videoProgressJson);

        if(typeof progressArray === 'string'){
            progressArray = JSON.parse(progressArray);
        }

        if (progressArray === undefined) return null;
        console.log("progressArray", progressArray);

        let currentLesson = progressArray.find(element => element.id == id)
        if (currentLesson !== undefined) {
            return currentLesson.watched;
        }

        return false;
    }


    useEffect(() => {

        console.log("Logged in Post", loggedIn)

        setWatched(getWatched(item.point))

        let file_url = null;


        if (typeof window !== "undefined") {

            if (item === undefined || item == null) return <></>;

            if (!item.content.includes('video')) {

                let lessons = JSON.parse(localStorage.getItem('lessons'));

                if (lessons != null) {

                    lessons.forEach(lesson => {

                        if (lesson.id == item.lesson_id) {

                            setFileUrl(lesson.file_url);

                        }
                    });

                }
            }

        }

    }, [])


    const meta = () => {
        return <div className={styles.meta}>
            <div>
                {item.duration && `${item.duration} min`}
            </div>
            <div>
                {item.content !== undefined && item.content.includes('video') && <IconVideo className={styles.metaItem} />}
                {item.content !== undefined && item.content.includes('file') && <IconPdf className={styles.metaItem} />}
            </div>
        </div>
    }

    return (

        <Link target={'_same'} to={loggedIn ? (fileUrl === null ? `/modules/${item.lesson_id || item.id}` : fileUrl) : `/checkout`}>


            <div
                className={`${styles.post} ${styles[type]} ${active ? styles.active : ""}`}
            >
                <div className={styles.imageWrapper} style={{ backgroundImage: `url(${item.image_url})` }}>
                    {watched &&
                        <div className={styles.watchedLabel}>Bereits angesehen</div>
                    }
                </div>

                <div className={styles.content}>
                    <div className={styles.label}>  {showPoint != undefined && showPoint == true ? (item.point + " ") : ""}{item.label}</div>
                    <h3 className={styles.heading}>{item.title}</h3>
                    <p className={styles.text}>{item.description}</p>
                    {type === 'large' && meta()}
                    <div className={styles.footer}>
                        <IconArrow className={styles.link} />
                    </div>



                </div>
                {type === 'small' && meta()}

            </div>
        </Link>
    )
}

export default Post
