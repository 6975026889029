// extracted by mini-css-extract-plugin
export var post = "postCard-module--post--3AbIR";
export var link = "postCard-module--link--2ZSuQ";
export var active = "postCard-module--active--2y4Xf";
export var watchedLabel = "postCard-module--watchedLabel--2eelg";
export var imageWrapper = "postCard-module--imageWrapper--25eZh";
export var content = "postCard-module--content--OJMHK";
export var label = "postCard-module--label--zZFn_";
export var heading = "postCard-module--heading--12WvN";
export var text = "postCard-module--text--2Y3PX";
export var meta = "postCard-module--meta--10HfH";
export var metaItem = "postCard-module--metaItem--QO7zc";
export var footer = "postCard-module--footer--3MZgk";
export var small = "postCard-module--small--3SSwO";
export var large = "postCard-module--large--1v047";
export var postFull = "postCard-module--postFull--utONh";
export var container = "postCard-module--container--294xM";